















































































import CollectionCardComponent from './CollectionCardComponent';
export default CollectionCardComponent;
