import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { searchTable, getAccountListResponseObj, getLMSCourseStatus } from '@/services/userService/users-api';
import store from '@/store';
import {
  getModule,
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  Action
} from 'vuex-module-decorators';
import { enableDisableAccount, updateAccountStatus } from '@/services/account/account';
import { CourseAPIResponse, CourseStatusStore } from '@/utilities/cms/courseStatus';
import { CourseStatus } from '@/utilities/cmsUtilities';
@Module({
  namespaced: true,
  name: 'accountListStore',
  store,
  dynamic: true
})

export class AccountListModule extends VuexModule {
  accountsdata: any = {};
  searchdata: any = {};
  public UpdatedAccountRes: any = {};
  public accountsPayload = JSON.parse(JSON.stringify(APP_CONST.ACCOUNT_PAYLOAD));
  public accountsPayloadFull = JSON.parse(JSON.stringify(APP_CONST.ACCOUNT_PAYLOAD_FULL));
  public allAccountDatas: any = [];
  public cmsCourseStatus: CourseStatusStore = {
    [CourseStatus.IN_PROGRESS]: [],
    [CourseStatus.COMPLETE]: [],
    [CourseStatus.TO_DO]: []
  };

  get accountList() {
    return this.accountsdata;
  }

  get accountPayload() {
    return this.accountsPayload;
  }

  get accountAllListData() {
    return this.allAccountDatas;
  }

  get fullAccountsPayload() {
    return this.accountsPayloadFull;
  }

  @Mutation
  mutateAccountPayload(payload: any) {
    this.accountsPayload = payload;
  }

  @Mutation
  mutateAccountsPayloadFull(payload: any) {
    this.accountsPayloadFull = payload;
  }

  @MutationAction
  getAccountList(payload: any) {
    payload.search = payload.search.replace(/%20/g, ' ');
    return getAccountListResponseObj(payload).then((response) => {
      response.data
        && response.data.results
        && response.data.results.length
        && response.data.results.forEach((element: any) => {
          element.bxSuccessLeadFullName = element.bxSuccessLeadFullName
            ? element.bxSuccessLeadFullName.trim()
            : '';
          element.bxPartnerLeadFullName = element.bxPartnerLeadFullName
            ? element.bxPartnerLeadFullName.trim()
            : '';
          element.primaryAccountAdminFullName = element.primaryAccountAdminFullName
            ? element.primaryAccountAdminFullName.trim()
            : '';
          element.scheduleDate = element.scheduleDate
            ? APP_UTILITIES.formatShortDateFullYear(element.scheduleDate)
            : '';
          element.selected = false;
        });
      return { accountsdata: response };
    }, (error) => {
      return { accountsdata: error };
    });
  }

  @Action
  allAccountData(payload: any) {
    return getAccountListResponseObj(payload).then((response: any) => {
      this.mutateAllAccountData(response.data.results);
    });
  }

  @Mutation
  mutateAllAccountData(allAccountDatas: any) {
    this.allAccountDatas = allAccountDatas;
  }

  @MutationAction
  searchAccountList(search_input: string) {
    return searchTable(search_input).then((response) => {
      return { searchdata: response };
    }, (error) => {
      return { searchdata: error };
    });
  }

  @MutationAction
  setAccountCourseStatuses() {
    const storage: CourseStatusStore = {
      [CourseStatus.IN_PROGRESS]: [],
      [CourseStatus.COMPLETE]: [],
      [CourseStatus.TO_DO]: []
    };
    return getLMSCourseStatus().then((response) => {
      const courses: CourseAPIResponse = response.data;
      if (!courses || !courses.length) {
        return { cmsCourseStatus: storage };
      }
      courses.forEach(course => {
        const status = course.status as CourseStatus;
        if (storage[status] && course.courseId) {
          storage[status].push(course.courseId);
        }
      });
      return { cmsCourseStatus: storage };
    }, (error) => {
      return { searchdata: error };
    });
  }

  @MutationAction
  public UpdateAccount(payload: object) {
    return updateAccountStatus(payload).then((response: any) => {
      return { UpdatedAccountRes: response };
    }, (error: any) => {
      return { UpdatedAccountRes: error };
    });
  }

  @Action
  async enableDisableAccount(payload: any) {
    const response = await enableDisableAccount(payload);
    return response;
  }
}
export default getModule(AccountListModule);