import { httpClient, httpLMSClient, httpCMSClient } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import { writeDataByKey, readDataByKey } from '@/utilities/idbUtility';

export function accountDetailResponse(id: string): Promise<any> {
  if (APP_UTILITIES.checkBrowserStatus()) {
    return getAccountDetail(id);
  }
  else{
    return readDataByKey(APP_CONST.IDB_NEW_ACCOUNT_INFO, APP_CONST.IDB_NEW_ACCOUNT_INFO).then((response: any) => {
      if(response){
        return { data: response, status: APP_CONST.RESPONSE_200, statusText: APP_CONST.SUCCESS_RESPONSE_STATUS };
      }
    }).catch(error => {
      return { data: error, status: APP_CONST.RESPONSE_400, statusText: error.statusText };
    });
  }
}

function getAccountDetail(id : string){
  return httpClient().get(`${API_CONST.NEW_ACCOUNTS_DATA_ENDPOINT}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function setAccountDetailResponse(data: any): Promise<any> {
  return httpClient().post(`${API_CONST.SAVE_NEW_ACCOUNTS_ENDPOINT}`, data).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function updateAccountDetailResponse(data: any): Promise<any> {
  return httpClient().put(`${API_CONST.UPDATE_ACCOUNTS_ENDPOINT}/${data.id}`, data).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function fetchExistingAccountData(id : string){
  return httpClient().get(`${API_CONST.FETCH_ACCOUNTS_DATA_ENDPOINT}/${id}/info`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

function autoSaveAccount(data : any){
  return httpClient().post(`${API_CONST.AUTO_SAVE_POST_ACCOUNT_ENDPOINT}`, data).then((response) => {
    return writeDataByKey(APP_CONST.IDB_NEW_ACCOUNT_INFO,APP_CONST.IDB_NEW_ACCOUNT_INFO, data).then((newAccRes: any) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch(error => {
      return { data: error, status: APP_CONST.RESPONSE_400, statusText: error.statusText };
    });
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function autoSaveAccountDetails(data: any): Promise<any> {
  if (APP_UTILITIES.checkBrowserStatus()) {
    return autoSaveAccount(data);
  }
  else{
    return navigator.serviceWorker.ready.then((sw) => {
      return writeDataByKey(APP_CONST.IDB_NEW_ACCOUNT_INFO,APP_CONST.IDB_NEW_ACCOUNT_INFO, data)
        .then((newAccRes: any) => {
          return sw.sync.register('Account');
        })
        .then(() => {
          const response = {
            name: 'Info_AccountAutoSaved',
            value: 'Account auto saved successfully.',
            resourceNotFound: false
          };
          return { data: response, status: APP_CONST.RESPONSE_200, statusText: APP_CONST.SUCCESS_RESPONSE_STATUS };
        })
        .catch((error: any) => {
          return { data: error, status: APP_CONST.RESPONSE_400, statusText: error.statusText };
        });
    });
  }
}

export function getPanoramaSKU() {
  return httpLMSClient().get(API_CONST.GET_PANORAMAS).then((response:any) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error:any) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}
export function getSublicenseByPanorama(panoramaId: string) {
  return httpLMSClient().get('/licenses/'+`${panoramaId}`).then((response:any)=> {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error:any) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function getAccountType() {
  return httpCMSClient().get(API_CONST.GET_ACCOUNT_TYPE).then((response:any) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error:any) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}
export function updateAccountStatus(payload:any) {
  return httpClient().put(`${API_CONST.ACCOUNT_STATUS}${payload.accountId}&status=${payload.status}`).then((response:any) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error:any) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function enableDisableAccount(payload:any){
  const encodedSearch = decodeURIComponent(payload.selectAllPayload.search);
  payload.selectAllPayload.search = encodedSearch;
  return httpClient().put(`${API_CONST.ENABLE_DISABLE_ACCOUNT}?status=${payload.status}`, payload.selectAllPayload).then((response:any) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error:any) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}
